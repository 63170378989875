<template>
  <div style="height: 100%">
    <nesi-user-common>
      <template #content>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 450px;
            flex-direction: column;
          "
        >
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              "
            >
              <el-upload
                action=""
                :http-request="fontRequest"
                :on-change="fontChange"
                :file-list="fontFileList"
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                :multiple="true"
                limit="1"
                :show-file-list="false"
                style="margin-right: 20px"
              >
                <template #default>
                  <div class="cert-image-wrapper">
                    <img
                      style="width: 100%; height: 100%"
                      class="el-upload-list__item-thumbnail"
                      :src="fontImage"
                      alt=""
                    />
                  </div>
                </template>
                <template #file="{ file }">
                  <div style="height: 100%">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                  </div>
                </template>
              </el-upload>
              <div style="margin-top: 10px">身份证正面照</div>
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              "
            >
              <el-upload
                action=""
                :http-request="backRequest"
                :on-change="backChange"
                :file-list="backFileList"
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                :multiple="true"
                limit="1"
                :show-file-list="false"
              >
                <template #default>
                  <div class="cert-image-wrapper">
                    <img
                      style="width: 100%; height: 100%"
                      class="el-upload-list__item-thumbnail"
                      :src="backImage"
                      alt=""
                    />
                  </div>
                </template>
                <template #file="{ file }">
                  <div style="height: 100%">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                  </div>
                </template>
              </el-upload>
              <div style="margin-top: 10px">身份证反面照</div>
            </div>
          </div>

          <div class="btn-wrapper">
            <el-button type="primary" @click="submitCert" :disabled="sDisable"
              >提交</el-button
            >
            <el-button type="pain" @click="reset">重置</el-button>
          </div>
        </div>
      </template>
    </nesi-user-common>
  </div>
</template>
<script setup>
import { defineComponent, ref, reactive } from 'vue'
import { uploadFile } from '@/api/sys'
import { uploadCardImg } from '@/api/user-house'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
defineComponent({
  NesiUserCommon
})
const store = useStore()
const router = useRouter()
const reset = () => {
  fontFileList.value = []
  fontFileListTemp.value = []
  backFileList.value = []
  backFileListTemp.value = []
  fontImage.value =
    'https://pc.nnfwzl.com/rent-house/rent-platform-web/font.png'
  backImage.value =
    'https://pc.nnfwzl.com/rent-house/rent-platform-web/back.png'
}
const sDisable = ref(false)
const submitCert = async () => {
  if (fontFileListTemp.value && !fontFileListTemp.value.length) {
    ElMessage.error('请上传身份证正面')
    return
  }
  if (backFileListTemp.value && !backFileListTemp.value.length) {
    ElMessage.error('请上传身份证反面')
    return
  }
  // 调用身份证校验接口
  const params = {
    front: fontFileListTemp.value[0].path,
    back: backFileListTemp.value[0].path
  }

  sDisable.value = true
  const resp = await uploadCardImg(params)
  sDisable.value = false
  if (resp && resp.code === 200) {
    ElMessage.success('上传成功！')
    await store.dispatch('user/getUserInfo')
    router.push({ path: '/' })
  } else {
    ElMessage.error(resp.message)
  }
}

const fontFileList = ref([])
const fontFileListTemp = ref([])
const fontImage = ref(
  'https://pc.nnfwzl.com/rent-house/rent-platform-web/font.png'
)
const fontChange = (file, fileList) => {
  fontFileList.value = fileList
}

const fontRequest = (option) => {
  request(option, true)
}
const backRequest = (option) => {
  request(option, false)
}
const request = async (option, isFont) => {
  const formData = new FormData()
  formData.append('file', option.file)
  const resp = await uploadFile(formData)
  if (isFont) {
    fontImage.value = resp.minio + resp.path
    fontFileListTemp.value = []
    fontFileList.value = []
    fontFileListTemp.value.push(resp)
  } else {
    backImage.value = resp.minio + resp.path
    backFileListTemp.value = []
    backFileList.value = []
    backFileListTemp.value.push(resp)
  }
}
const backFileList = ref([])
const backFileListTemp = ref([])
const backImage = ref(
  'https://pc.nnfwzl.com/rent-house/rent-platform-web/back.png'
)
const backChange = (file, fileList) => {
  backFileList.value = fileList
}
</script>
<style lang="scss" scoped>
.btn-wrapper {
  margin-top: 40px;
  padding: 0 10px;
}
.cert-image-wrapper {
  height: 200px;
  width: 300px;
  padding: 5px;
  border: 1px dashed #c0ccda;
  &:hover {
    border-color: #409eff;
    color: #409eff;
  }
}
</style>
